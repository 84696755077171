type JobSections = {
  introduction: string;
  responsibilities: string[];
  requirements: string[];
  desiredTraits: string[];
  applicationInfo: string;
};

export function extractJobSections(htmlString: string): JobSections {
  // Create a temporary DOM element to parse the HTML
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  
  const sections: JobSections = {
    introduction: '',
    responsibilities: [],
    requirements: [],
    desiredTraits: [],
    applicationInfo: ''
  };

  // Get all paragraphs and lists
  const elements = doc.body.children;

  let currentSection: keyof JobSections | null = null;

  Array.from(elements).forEach((element) => {
    const text = element.textContent?.trim() || '';

    // Determine which section we're in based on the text
    if (text === 'Introduction:') {
      currentSection = 'introduction';
    } else if (text === 'Responsibilities:') {
      currentSection = 'responsibilities';
    } else if (text === 'Requirements:') {
      currentSection = 'requirements';
    } else if (text === 'Desired Traits:') {
      currentSection = 'desiredTraits';
    } else {
      // Process the content based on current section
      if (currentSection === 'introduction' && element.tagName === 'P') {
        sections.introduction = text;
      } else if (currentSection && element.tagName === 'UL') {
        const items = Array.from(element.getElementsByTagName('li'))
          .map(li => li.textContent?.trim())
          .filter((text): text is string => !!text);

          if (currentSection !== 'introduction' && currentSection !== 'applicationInfo') {
            // Now TypeScript knows we're only dealing with array properties
            sections[currentSection as 'responsibilities' | 'requirements' | 'desiredTraits'] = items;
          }
      } else if (text.includes('HR@Blackbanx.com')) {
        sections.applicationInfo = text;
      }
    }
  });

  return sections;
}
