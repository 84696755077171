import React from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow_down.svg";

export const JobSection = ({ title, children }: { title: string; children: React.ReactNode }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  return (
    <div
      style={{
        border: '1px solid #F2F2F2',
        borderRadius: '1rem',
        overflow: 'hidden'
      }}
    >
      <button
        onClick={toggleExpand}
        aria-expanded={isExpanded}
        aria-controls="expandable-content"
        style={{
          width: '100%',
          padding: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent', // bg-gray-100
          transition: 'background-color 150ms ease-in-out',
          outline: 'none',
          border: 'none',
        }}
      >
        <p style={{ fontSize: '1.6rem', color: "#A1A1A1" }}>{`${title}:`}</p>
        <ArrowDownIcon
          style={{
            transform: isExpanded ? 'rotate(180deg)' : 'none',
            transition: 'transform 200ms'
          }}
        />
      </button>
      <div
        id="expandable-content"
        style={{
          overflow: 'hidden',
          transition: 'all 200ms ease-in-out',
          maxHeight: isExpanded ? 'fit-content' : '0'
        }}
      >
        <div style={{ padding: '1rem' }}>{children}</div>
      </div>
    </div>
  )
};