import React from "react";
import { Vacations } from "./vacations.styles";
import { ReactComponent as DollarIcon } from "../../assets/icons/vacations/dollar-sign.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/vacations/clock.svg";
import { Wrap } from "../wrap/wrap.component";
import { extractJobSections } from "../../utils/extractJobSections";
import { JobSection } from "./jobSection";
import { SelectOption } from "../inputs/select/select.types";
import GlobeIcon from "../../assets/icons/vacations/globe.png";

export type VacationItem = {
  title: string;
  description: string;
  variant: "full_time" | "part_time";
  location: string;
  salary: {
    from: number;
    to: number;
  };
};

export type VacationSection = {
  title: string;
  vacations: VacationItem[];
};

export type VacationsComponentProps = {
  sections: VacationSection[];
  location: string
};

export const VacationsComponent: React.FC<VacationsComponentProps> = (props) => {
  const { sections, location } = props;
  return (
    <Vacations.Body>
      {sections.map((section, idx) => (
        <Vacations.ContainerSection key={`vacation-section-${idx}`}>
          <Vacations.SectionTitle>{section.title}</Vacations.SectionTitle>
          <Vacations.Items.Container>
            {section.vacations.map((vacation, idx) => {
              const { Item: Vacation } = Vacations.Items;
              const sections = extractJobSections(vacation.description);
              return (
                <Vacation.Container key={`vacation-item-${idx}`}>
                  <Vacation.Title>{vacation.title}</Vacation.Title>
                  <Vacation.Footer.Data>
                    <Vacation.Footer.Item>
                      <Wrap sx={{ display: "flex", alignItems: "center" }}>
                        <ClockIcon /> <span style={{ marginLeft: "0.5rem" }}>{vacation?.variant || "N/A"}</span>
                      </Wrap>
                    </Vacation.Footer.Item>
                    <Vacation.Footer.Item>
                      <Wrap sx={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                        <DollarIcon />
                      </Wrap>
                      {vacation.salary.to || "N/A"}
                    </Vacation.Footer.Item>
                    <Vacation.Footer.Item>
                      <Wrap sx={{ display: "flex", alignItems: "center", marginRight: "1rem" }}>
                        <img src={GlobeIcon} alt="Globe" style={{ width: "2rem", height: "2rem" }} />
                      </Wrap>
                      {vacation.location || "N/A"}
                    </Vacation.Footer.Item>
                  </Vacation.Footer.Data>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                      marginTop: "1rem",
                    }}
                  >
                    {sections.introduction && (
                      <div>
                        <p>Introduction:</p>
                        <p>{sections.introduction}</p>
                      </div>
                    )}
                    {sections.responsibilities?.length > 0 && (
                      <JobSection title="Responsibilities">
                        <ul>
                          {sections.responsibilities.map((responsibility, idx) => (
                          <li key={`responsibility-${idx}`}>{responsibility}</li>
                        ))}
                      </ul> 
                      </JobSection>
                    )}
                    {sections.requirements?.length > 0 && (
                      <JobSection title="Requirements">
                        <ul>
                          {sections.requirements.map((requirement, idx) => (
                          <li key={`requirement-${idx}`}>{requirement}</li>
                        ))}
                      </ul> 
                      </JobSection>
                    )}
                    {sections.desiredTraits?.length > 0 && (
                      <JobSection title="Desired Traits">
                        <ul>
                          {sections.desiredTraits.map((desiredTrait, idx) => (
                          <li key={`desiredTrait-${idx}`}>{desiredTrait}</li>
                        ))}
                      </ul> 
                      </JobSection>
                    )}
                    <p>{sections.applicationInfo}</p>
                  </div>
                </Vacation.Container>
              );
            })}
          </Vacations.Items.Container>
        </Vacations.ContainerSection>
      ))}
    </Vacations.Body>
  );
};